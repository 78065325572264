import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
  scmAuthApiRef,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  errorApiRef,
  fetchApiRef,
  gitlabAuthApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';


import {
  VisitsWebStorageApi,
  visitsApiRef,
} from '@backstage/plugin-home';

import { TechRadarClient } from './lib/TechRadarClient';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';



export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmAuthApiRef,
    deps: {
      gitlabAuthApi: gitlabAuthApiRef,
    },
    factory: ({ gitlabAuthApi }) => ScmAuth.forGitlab(gitlabAuthApi),
  }),
  createApiFactory({
    api: visitsApiRef,
    deps: {
      identityApi: identityApiRef,
      errorApi: errorApiRef
    },
    factory: ({ identityApi, errorApi }) => VisitsWebStorageApi.create({ identityApi, errorApi }),
  }),
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: techRadarApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
      identityApi: identityApiRef
    },
    factory: ({discoveryApi, fetchApi, identityApi}) => {
      return new TechRadarClient( {discoveryApi, fetchApi, identityApi })
    }
  }),
  //ScmAuth.createDefaultApiFactory(),
];
